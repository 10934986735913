import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
 
export const getCnfParameterDtls = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/getCnfParameterDtls', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfParameterDtlById = async (id) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/getCnfParameterDtlById', { id });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCnfParameterDtlBy = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/getCnfParameterDtlBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createCnfParameterDtl = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/createCnfParameterDtl', createBy(data));
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCnfParameterDtl = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/updateCnfParameterDtl', updateBy(data));
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCnfParameterDtls = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/activeCnfParameterDtls', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCnfParameterDtls = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/inActiveCnfParameterDtls', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCnfParameterDtls = async (data) => {
  try {
    const response = await client.post('/cnf-parameter-dtl/deleteCnfParameterDtls', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}