import client from './instance'


export const getCnfPartNos = async (data) => {
  try {
    const response = await client.post('/cnf-part-no/getCnfPartNos', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfPartNoById = async (id) => {
  try {
    const response = await client.post('/cnf-part-no/getCnfPartNoById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCnfPartNoBy = async (data) => {
  try {
    const response = await client.post('/cnf-part-no/getCnfPartNoBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createCnfPartNos = async (data) => {
  try {
    const response = await client.post('/cnf-part-no/createCnfPartNos', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

// export const createCnfPartNo = async (data) => {
//   try {
//     const formData = new FormData();
//     formData.append("cmContent", JSON.stringify(createBy(data)))
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
//       formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.thumbnailFileUrl)
//     }
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.videoFileUrl)) {
//       formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.videoFileUrl)
//     }

//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
//       formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.thumbnailFileUrl)
//     }
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.videoFileUrl)) {
//       formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.videoFileUrl)
//     }

//     if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
//       const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
//       for (const i in cmContentChannelInfoButton) {
//         if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
//           formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
//         }
//       }
//     }

//     const response = await client.post('/cnf-part-no/createCnfPartNo', formData);

//     return response.data;
//   } catch (error) {
//     console.log(error)
//     throw error;
//   }
// }

// export const updateCnfPartNo = async (data) => {
//   try {
//     const formData = new FormData();
//     formData.append("cmContent", JSON.stringify(updateBy(data)))
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
//       formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.thumbnailFileUrl)
//     }
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.videoFileUrl)) {
//       formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.videoFileUrl)
//     }
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
//       formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.thumbnailFileUrl)
//     }
//     if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.videoFileUrl)) {
//       formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.videoFileUrl)
//     }
//     if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
//       const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
//       for (const i in cmContentChannelInfoButton) {
//         if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
//           formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
//         }
//       }
//     }

//     if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

//     const response = await client.post('/cnf-part-no/updateCnfPartNo', formData);

//     return response.data;



//     // const response = await client.post('/cnf-part-no/updateCnfPartNo', data);

//     // return response.data;
//   } catch (error) {
//     console.log(error)
//     throw error;
//   }
// }

export const activeCnfPartNos = async (data) => {
  try {
    const response = await client.post('/cnf-part-no/activeCnfPartNos', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCnfPartNos = async (data) => {
  try {
    const response = await client.post('/cnf-part-no/inActiveCnfPartNos', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCnfPartNos = async (data) => {
  try {
    const response = await client.post('/cnf-part-no/deleteCnfPartNos', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}