import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'


export const getCmTopservCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/getCmTopservCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmTopservCampaignBy = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/getCmTopservCampaignBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmTopservCampaignById = async (id) => {
  try {
    const response = await client.post('/cm-topserv-campaign/getCmTopservCampaignById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const haveReferenceCmTopservCampaign = async (id) => {
  try {
    const response = await client.post('/cm-topserv-campaign/haveReferenceCmTopservCampaign', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createCmTopservCampaign = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/createCmTopservCampaign', createBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCmTopservCampaign = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/updateCmTopservCampaign', updateBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCmTopservCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/inActiveCmTopservCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCmTopservCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/activeCmTopservCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCmTopservCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-topserv-campaign/deleteCmTopservCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}