import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
import client2 from './instanceNoLodding'


export const getCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContents', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmContentsByCodeOrName = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentsByCodeOrName', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const generateLastCode = async (code) => {
  try {
    const response = await client.post('/cm-content/generateLastCode', { code });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmContentById = async (id) => {
  try {
    const response = await client2.post('/cm-content/getCmContentById', { id });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmContentBy = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const getCmContentCareMessage = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentCareMessage', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const createCmContent = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(createBy(data)))
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
    //   formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    // }
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
    //   formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].videoFileUrl)
    // }

    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl)
    }

    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    const response = await client.post('/cm-content/createCmContent', formData);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCmContent = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(updateBy(data)))
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
    //   formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    // }
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
    //   formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].videoFileUrl)
    // }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl)
    }
    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

    const response = await client.post('/cm-content/updateCmContent', formData);

    return response.data;



    // const response = await client.post('/cm-content/updateCmContent', data);

    // return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/activeCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/inActiveCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/deleteCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}