import instance from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

const client = instance

export const getAudiences = async (data) => {
  try {
    const response = await client.post('/audience/getAudiences', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getAudienceById = async (id) => {
  try {
    const response = await client.post('/audience/getAudienceById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getAudienceByCodeOrName = async (data) => {
  try {
    const response = await client.post('/audience/getAudienceByCodeOrName', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getAudienceBy = async (data) => {
  try {
    const response = await client.post('/audience/getAudienceBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getAudienceBySearch = async (data) => {
  try {
    const response = await client.post('/audience/getAudienceBySearch', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createAudience = async (data) => {
  try {
    const response = await client.post('/audience/createAudience', createBy(data));
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateAudience = async (data) => {
  try {
    const response = await client.post('/audience/updateAudience', updateBy(data));
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeAudiences = async (data) => {
  try {
    const response = await client.post('/audience/activeAudiences', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const inActiveAudiences = async (data) => {
  try {
    const response = await client.post('/audience/inActiveAudiences', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteAudiences = async (data) => {
  try {
    const response = await client.post('/audience/deleteAudiences', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const calCriteria = async (data) => {
  try {
    const response = await client.post('/audience/calCriteria', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const targetSizeCriteria = async (data) => {
  try {
    const response = await client.post('/audience/targetSizeCriteria', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}

export const calculatorAudience = async (data) => {
  try {
    const response = await client.post('/audience/calculatorAudience', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}

export const generateLastCode = async (code) => {
  try {
    const response = await client.post('/audience/generateLastCode', { code });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}


export const exportAudience = async (data) => {
  try {
  
    const response = await client.post('/audience/exportAudience', data, { responseType: 'blob' });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}
