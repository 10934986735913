import client from './instance'
import client2 from './instanceNoLodding'
export const unread = async (data) => {
  try {
    const response = await client.post('/care-message-notification-hdr/unread');

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getNotiByActive = async (data) => {
  try {
    const response = await client2.post('/care-message-notification-hdr/getNotiByActive', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const readNoti = async (data) => {
  try {
    const response = await client2.post('/care-message-notification-hdr/readNoti', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteNoti = async (data) => {
  try {
    const response = await client2.post('/care-message-notification-hdr/deleteNoti', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const updateNoti = async (data) => {
  try {
    const response = await client2.post('/care-message-notification-hdr/updateNoti', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
