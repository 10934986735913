import client from './instance'
import client2 from './instanceNoLodding'
import { createBy, updateBy } from 'utils/apiHelper'

export const getCareMessageUserTransactions = async (data) => {
  try {
    const response = await client.post('/care-message-user-transaction/getCareMessageUserTransactions', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const createCareMessageUserTransaction = async (data) => {
  try {
    const response = await client2.post('/care-message-user-transaction/createCareMessageUserTransaction', createBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
