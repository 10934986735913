import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

export const getTpActivities = async (data) => {
  try {
    const response = await client.post('/tp-activitie/getTpActivities', data );
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
export const getTpActivitiesByIdOrName = async (data) => {
  try {
    const { auth } = store.getState()

    const response = await client.post('/tp-activitie/getTpActivitiesByIdOrName', data, {
      headers: { 'x-access-token': auth.accessToken, }
    });

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const getTpActivitieBy = async (data) => {
  try {
    const response = await client.post('/tp-activitie/getTpActivitieBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getTpActivitieById = async (id) => {
  try {
    const response = await client.post('/tp-activitie/getTpActivitieById', { id });

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const createTpActivitie = async (data) => {
  try {
    const response = await client.post('/tp-activitie/createTpActivitie', createBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const updateTpActivitie = async (data) => {
  try {
    const response = await client.post('/tp-activitie/updateTpActivitie', updateBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const activeTpActivities = async (data) => {
  try {
    const response = await client.post('/tp-activitie/activeTpActivities', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export const inActiveTpActivities = async (data) => {
  try {
    const response = await client.post('/tp-activitie/inActiveTpActivities', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const deleteTpActivities = async (data) => {
  try {
    const response = await client.post('/tp-activitie/deleteTpActivities', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}