// import store from 'store'

// const { auth } = store.getState()

const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : 'https://mktapp-mob-api-care.toyota.co.th/',//https://mktapp-mob-api-care-dev.toyota.co.th',
  ALIVE_X_URL: process.env.ALIVE_X_URL ? process.env.ALIVE_X_URL : 'https://toyotapoint.buzzebees.com',
  CLIENT_URL: 'http://localhost:3000/',
  // HEADERS: { headers: {'x-access-token': auth.accessToken,} }
}
export default config;
