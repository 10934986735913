import store from 'store'
import axios from 'axios'
import config from './config'
import Swal from 'sweetalert2'

const { auth } = store.getState()
const instanceNoLodding  = axios.create({
    baseURL: config.API_BASE_URL,
})

export default instanceNoLodding;
