
export function mapSearch(search, relation = []) {
    const params = {}
    for (const key in search) {
        if (search[key]) {
            if (typeof (search[key]) === 'object') {
                if (search[key].length === undefined) {
                    params[key] = mapSearch(search[key])
                } else {
                    if (search[key].length > 0) {
                        params[key] = search[key]
                    }
                }
            } else {
                console.log(typeof (search[key]))
                if (typeof (search[key]) === 'number') {
                    params[key] = search[key]
                } else {
                    params[key] = search[key].replace(/_/g, '\\_')
                }
                if (key == 'activeStatus') {
                    params[key] == 'All' ? params[key] = '' : params[key] = search[key]
                }
            }
        }
    }
    for (const r of relation) {
        if (params[r]) {
            params[r] = { id: params[r].id }
        }
    }
    return params
}

export function mapSearchOr(keyword, value, relation = []) {
    const params = {}
    for (let i in value) {
        params[value[i]] = keyword
    }
    return {
        or: params
    }
}

export function isFile(input) {
    if ('File' in window && input instanceof File)
        return true;
    else return false;
}

export function renderFile(file) {
    const reader = new FileReader()
    reader.onloadend = () => {
        console.log(reader.result)
        return reader.result
    }
    reader.readAsDataURL(file)
}