import React, { Suspense, useEffect, useRef } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import { authAPI } from 'api';
import Swal from 'sweetalert2';
import { authAction } from './actions'
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'))
const Login = React.lazy(() => import('./views/Login'))
const WebView = React.lazy(() => import('./views/webView'))
const WebViewApp = React.lazy(() => import('./views/webViewApp'))
const WebViewSms = React.lazy(() => import('./views/webViewSms'))

const basename = process.env.PUBLIC_URL;
const arrayName = ['/web-view/', '/web-view-app', '/web-view-sms/']
const App = () => {
  const dispatch = useDispatch()
  const initialized = useRef(false)
  const auth = useSelector((state) => state.auth)
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      init()
    }

  }, [])
  const init = async (e) => {
    const params = new URLSearchParams(window.location.search)
    let a = params.get('auth')
    if (a) {
      try {
        const data = await authAPI.login(a)

        dispatch(authAction.login({
          user: data,
          accessToken: data.accessToken,
        }))
        //navigate('/', { replace: true })
        window.location.replace(window.location.origin)
      } catch (error) {
        console.log(error)
        await Swal.fire({ title: "ไม่สามารถล็อคอินได้ !", text: 'คำขอเกิดข้อผิดพลาด', icon: "error", })
        handleLogout();
      }
    }
  }
  // const redirectLoginCas = () => {
  //   const casUrl = process.env.REACT_APP_SC2_CAS_URL ? process.env.REACT_APP_SC2_CAS_URL : 'https://sc2.tmap-em.toyota-asia.com'
  //   const loginUrl = casUrl + "/cas/login?service=" + encodeURIComponent(window.location.origin) + "%2Flogin%2F&locale=th_TH&un="
  //   window.location.replace(loginUrl)
  // }
  const handleLogout = (e) => {

    console.log('handleLogout')
    localStorage.clear()
    const casUrl = process.env.REACT_APP_SC2_CAS_URL ? process.env.REACT_APP_SC2_CAS_URL : 'https://sc2.tmap-em.toyota-asia.com'
    const logoutUrl = casUrl + "/logout"
    openAndClosePopup(logoutUrl, 'LOGUT', 500, 500, 2000); //FIXME FOR TEST
    // dispatch(authAction.logout())
  }

  const openAndClosePopup = (url, windowName, width, height, delay) => {
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const options = `width=${width},height=${height},left=${left},top=${top}`;
    const popup = window.open(url, windowName, options);

    // Close the popup after the specified delay
    setTimeout(function () {
      popup.close();
      dispatch(authAction.logout())
    }, delay);
  }
  console.log("window.location.pathname>>", window.location.pathname)
  return (
    <BrowserRouter basename={basename}>
      <Suspense fallback={<div className="pt-3 text-center">Loading....</div>}>
        {arrayName.includes(window.location.pathname) ?
          <Routes>
            <Route exact path="/web-view/*" name="Login Page" element={<WebView />} />
            <Route exact path="/web-view-app/*" name="Login Page" element={<WebViewApp />} />
            <Route exact path="/web-view-sms/*" name="Login Page" element={<WebViewSms />} />
          </Routes>
          :
          auth.isAuthenticated ? <DefaultLayout /> : (
            <Routes>
              <Route path="/login" name="Login Page" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          )
        }
      </Suspense>
    </BrowserRouter>
  )
}

export default App

