import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

export const getCmBzbCampaigns = async (data) => {
  try {
    // if(data && data.search && data.search.theOneCampaign){
    //   data.search.theOneCampaign = {theOneCampaignId:data.search.theOneCampaign}
    // }
    const response = await client.post('/cm-bzb-campaign/getCmBzbCampaigns',data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmBzbCampaignBy = async (data) => {
  try {
    const response = await client.post('/cm-bzb-campaign/getCmBzbCampaignBy',data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmBzbCampaignById = async (id) => {
  try {
    const response = await client.post('/cm-bzb-campaign/getCmBzbCampaignById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const haveReferenceCmBzbCampaign = async (id) => {
  try {
    const response = await client.post('/cm-bzb-campaign/haveReferenceCmBzbCampaign', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const createCmBzbCampaign = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmBzbCampaign", JSON.stringify(createBy(data.cmBzbCampaign)))

    if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

    const response = await client.post('/cm-bzb-campaign/createCmBzbCampaign', formData);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCmBzbCampaign = async (data) => {
  try {


    const formData = new FormData();
    formData.append("cmBzbCampaign", JSON.stringify(updateBy(data.cmBzbCampaign)))

    if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

    const response = await client.post('/cm-bzb-campaign/updateCmBzbCampaign', formData);

    return response.data;



    // const response = await client.post('/cm-bzb-campaign/updateCmBzbCampaign', data);

    // return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCmBzbCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-bzb-campaign/activeCmBzbCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const inActiveCmBzbCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-bzb-campaign/inActiveCmBzbCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const deleteCmBzbCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-bzb-campaign/deleteCmBzbCampaigns', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}