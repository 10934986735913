import client from './instance'

export const upload = async (file) => {
  try {
    const formData = new FormData();
    const blob = new Blob([file], { type: 'image/png' });
    formData.append('file', file);
    console.log("formData.file>>>", formData.file)
    // const config = {
    //   headers: {
    //     'Content-Type': 'multipart/form-data', // Ensure multipart/form-data is used
    //     // Any other headers you need
    //   }
    // };
    const response = await client.post('/file/uploads', formData, { 'Content-Type': "multipart/form-data; charset=utf-8" });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
