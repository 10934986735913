import { isFile } from "./miscHelper"
export function createBy(data) {
    const params = loopKey(data, true)
    return params
}

export function updateBy(data) {
    const params = loopKey(data, false)
    return params
}
function loopKey(data, create) {
    const params = data
    for (const key in params) {
        if (typeof (params[key]) === 'object' && !isFile(params[key]) && params[key] != null) {
            params[key] = loopKey(params[key], create)
        } else if (typeof (params[key]) === 'string') {
            if (params[key].startsWith('data:image')) {
                params[key] = ''
            }
        }
    }
    return create ? addCreateBy(params) : addUpdateBy(params)
}
function addCreateBy(data) {
    if (data.length > 0) {
        return data
    }
    const params = data
    params.createdBy = ''//athun
    params.createdDate = new Date()
    params.updateBy = ''//athun
    params.updateDate = new Date()
    params.version = 1
    // if (!params.activeStatus) {
    //     params.activeStatus = "N"
    // }
    params.version = 1
    return params
}

function addUpdateBy(data) {
    if (data.length > 0) {
        return data
    }
    const params = data
    params.createdBy = params?.createdBy ? params.createdBy : ''//athun
    params.createdDate = params?.createdDate ? params.createdDate : new Date()
    params.updateBy = ''//athun
    params.updateDate = new Date()
    params.version = 1
    // if (!params.activeStatus) {
    //     params.activeStatus = "N"
    // }
    params.version = 1
    return params
}

