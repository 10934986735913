import instance from './instance'
import axios from 'axios'
import config from './config'
import store from 'store'

const client = instance
const client2 = axios.create({
  baseURL: config.API_BASE_URL,
});
const { auth } = store.getState()

export const getResult = async (data) => {
  try {
    const response = await client2.post('/global-process/getResult', data, { headers: { 'x-access-token': auth.accessToken, } });
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getResultExport = async (data) => {
  try {
    const response = await client2.post('/global-process/getResult', data, { headers: { 'x-access-token': auth.accessToken, } });
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const calCriteria = async (data) => {
  try {
    const response = await client.post('/audience/calCriteria', data, { headers: { 'x-access-token': auth.accessToken, } });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const targetSizeCriteria = async (data) => {
  try {
    const response = await client.post('/audience/targetSizeCriteria', data, { headers: { 'x-access-token': auth.accessToken, } });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}

export const calculatorAudience = async (data) => {
  try {
    const response = await client.post('/audience/calculatorAudience', data, { headers: { 'x-access-token': auth.accessToken, } });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}