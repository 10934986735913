import client from './instance'
import client2 from './instanceNoLodding'
export const getTpActSchTransactions = async (data) => {
  try {
    const response = await client.post('/tp-act-sch-transection/getTpActSchTransactions', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
export const getTpActSchTransactionsDtl = async (data) => {
  try {
    const response = await client.post('/tp-act-sch-transection/getTpActSchTransactionsDtl', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
export const updateTpActSchTransactionDetail = async (data) => {
  try {
    const response = await client2.post('/tp-act-sch-transection/updateTpActSchTransactionDetail', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
export const updateReadTpActSchTransactionDetail = async (data) => {
  try {
    const response = await client2.post('/tp-act-sch-transection/updateReadTpActSchTransactionDetail', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};