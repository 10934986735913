import client from './instance'

export const getUsers = async (data) => {
  try {
    const response = await client.post('/user/getUsers', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const activeUsers = async (data) => {
  try {
    const response = await client.post('/user/activeUsers', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const blockUsers = async (data) => {
  try {
    const response = await client.post('/user/blockUsers', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const deleteUsers = async (data) => {
  try {
    const response = await client.post('/user/deleteUsers', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const updateRole = async (data) => {
  try {
    const response = await client.post('/user/updateRole', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

// export const getUserById = async (userId) => {
//   try {
//     const response = await client.post('/user/getUserById', { userId });

//     return response.data;
//   } catch (error) {
//     console.error(error)
//     throw error;
//   }
// }

// export const createUser = async (data) => {
//   try {
//     const response = await client.post('/user/createUser', data);

//     return response.data;
//   } catch (error) {
//     console.error(error)
//     throw error;
//   }
// }

// export const updateUser = async (data) => {
//   try {
//     const formData = new FormData();

//     formData.append("user", JSON.stringify(data.user))

//     if (data.user_profile_img) formData.append("user_profile_img", data.user_profile_img)

//     const response = await client.post('/user/updateUser', formData);

//     return response.data;
//   } catch (error) {
//     console.error(error)
//     throw error;
//   }
// }

// export const deleteUser = async (data) => {
//   try {
//     const response = await client.post('/user/deleteUser', data);

//     return response.data;
//   } catch (error) {
//     console.error(error)
//     throw error;
//   }
// }