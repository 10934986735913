import client from './instance'

import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper';

export const getTouchpoints = async (data) => {
  try {
    const response = await client.post('/touchpoint/getTouchpoints', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getTouchpointById = async (id) => {
  try {
    const response = await client.post('/touchpoint/getTouchpointById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getTouchpointBy = async (data) => {
  try {
    const response = await client.post('/touchpoint/getTouchpointBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const generateLastCode = async (code) => {
  try {
    const response = await client.post('/touchpoint/generateLastCode', { code });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const getTouchpointCareMessage = async (data) => {
  try {
    const response = await client.post('/touchpoint/getTouchpointCareMessage', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const createTouchpoint = async (data) => {
  try {
    const formData = new FormData();
    formData.append("touchpoint", JSON.stringify(createBy(data)))
    for (let activity in data.tpActivities) {
      for (let i in data.tpActivities[activity].tpActTargetGroup) {
        if (isFile(data.tpActivities[activity].tpActTargetGroup[i].actTargetGroupUpload)) {
          formData.append("actTargetGroupUpload-" + activity + "-" + i, data.tpActivities[activity].tpActTargetGroup[i].actTargetGroupUpload)
        }
      }

    }
    const response = await client.post('/touchpoint/createTouchpoint', formData);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateTouchpoint = async (data) => {
  try {
    const formData = new FormData();
    formData.append("touchpoint", JSON.stringify(updateBy(data)))
    for (let activity in data.tpActivities) {
      for (let i in data.tpActivities[activity].tpActTargetGroup) {
        if (isFile(data.tpActivities[activity].tpActTargetGroup[i].actTargetGroupUpload)) {
          formData.append("actTargetGroupUpload-" + activity + "-" + i, data.tpActivities[activity].tpActTargetGroup[i].actTargetGroupUpload)
        }
      }

    }
    const response = await client.post('/touchpoint/updateTouchpoint', formData);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeTouchpoints = async (data) => {
  try {
    const response = await client.post('/touchpoint/activeTouchpoints', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveTouchpoints = async (data) => {
  try {
    const response = await client.post('/touchpoint/inActiveTouchpoints', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteTouchpoints = async (data) => {
  try {
    const response = await client.post('/touchpoint/deleteTouchpoints', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const calculateTouchpoint = async (data) => {
  try {
    const response = await client.post('/touchpoint/calculateTouchpoint', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const exportCalculateTouchpoint = async (data) => {
  try {
    const response = await client.post('/touchpoint/exportCalculateTouchpoint', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const exportFromCalendar = async (data) => {
  try {
    const response = await client.post('/touchpoint/exportFromCalendar', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getReportTouchpointActivity = async (data) => {
  try {
    const response = await client.post('/touchpoint/getReportTouchpointActivity', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const exportFromReport = async (data) => {
  try {
    const response = await client.post('/touchpoint/exportFromReport', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }

}

export const countTargetTouchpoint = async (data) => {
  try {
    const response = await client.post('/touchpoint/countTargetTouchpoint', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const downloadUploadAudience = async (data) => {
  try {
    const response = await client.post('/touchpoint/downloadUploadAudience', data);
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
