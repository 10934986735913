import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'



export const getRolePermissions = async (data) => {
  try {
    const response = await client.post('/rolePermission/getRolePermissions', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const getRolePermissionBy = async (data) => {
  try {
    const response = await client.post('/rolePermission/getRolePermissionBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getRolePermissionById = async (id) => {
  try {
    const response = await client.post('/rolePermission/getRolePermissionById', { id });

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const createRolePermission = async (data) => {
  try {
    const response = await client.post('/rolePermission/createRolePermission', createBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const updateRolePermission = async (data) => {
  try {
    const response = await client.post('/rolePermission/updateRolePermission', updateBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const activeRolePermissions = async (data) => {
  try {
    const response = await client.post('/rolePermission/activeRolePermissions', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export const inActiveRolePermissions = async (data) => {
  try {
    const response = await client.post('/rolePermission/inActiveRolePermissions', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const deleteRolePermissions = async (data) => {
  try {
    const response = await client.post('/rolePermission/deleteRolePermissions', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}