import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

 

export const getRolePermissionConfigs = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/getRolePermissionConfigs', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const getRolePermissionConfigBy = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/getRolePermissionConfigBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getRolePermissionConfigById = async (uuid) => {
  try {
    const response = await client.post('/rolePermissionConfig/getRolePermissionConfigById', uuid );

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const createRolePermissionConfig = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/createRolePermissionConfig', createBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const createRolePermissionConfigs = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/createRolePermissionConfigs', createBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const updateRolePermissionConfig = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/updateRolePermissionConfig', updateBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const activeRolePermissionConfigs = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/activeRolePermissionConfigs', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export const inActiveRolePermissionConfigs = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/inActiveRolePermissionConfigs', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const deleteRolePermissionConfigs = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/deleteRolePermissionConfigs', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export const deleteRolePermissionConfigByRole = async (data) => {
  try {
    const response = await client.post('/rolePermissionConfig/deleteRolePermissionConfigByRole', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
