import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'

 

export const getCnfAudienceSelections = async (data) => {
  try {
    const response = await client.post('/cnf-audience-selection/getCnfAudienceSelections', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfAudienceSelectionGroupBy = async (data) => {
  try {
    const response = await client.post('/cnf-audience-selection/getCnfAudienceSelectionGroupBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfAudienceSelectionById = async (id) => {
  try {
    const response = await client.post('/cnf-audience-selection/getCnfAudienceSelectionById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCnfAudienceSelectionBy = async (data) => {
  try {
    const response = await client.post('/cnf-audience-selection/getCnfAudienceSelectionBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createCnfAudienceSelection = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(createBy(data)))
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.videoFileUrl)
    }

    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.videoFileUrl)
    }

    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    const response = await client.post('/cnf-audience-selection/createCnfAudienceSelection', formData);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCnfAudienceSelection = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(updateBy(data)))
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.videoFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.videoFileUrl)
    }
    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

    const response = await client.post('/cnf-audience-selection/updateCnfAudienceSelection', formData);

    return response.data;



    // const response = await client.post('/cnf-audience-selection/updateCnfAudienceSelection', data);

    // return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCnfAudienceSelections = async (data) => {
  try {
    const response = await client.post('/cnf-audience-selection/activeCnfAudienceSelections', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCnfAudienceSelections = async (data) => {
  try {
    const response = await client.post('/cnf-audience-selection/deleteCnfAudienceSelections', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}