import axios from 'axios'
import config from './config'

const client = axios.create({
  baseURL: config.API_BASE_URL,
});

export const login = async (tokenId) => {
  
  try {
    const data=''
    const response = await client.post('/sc2-user-management/getAuthenticate?auth='+tokenId, data);
    //const response = await client.post('/auth/login', data);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const refreshTokens = async (refreshToken) => {
  try {
    const response = await client.post('/auth/refresh', { refreshToken });

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}