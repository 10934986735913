import client from './instance'

export const getCnfCareAudienceSelectorsGroupBy = async (data) => {
  try {
    const response = await client.post('/cnf-care-audience-selector/getCnfCareAudienceSelectorsGroupBy', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const createCnfCareAudienceSelectors = async (data) => {
  try {
    const response = await client.post('/cnf-care-audience-selector/createCnfCareAudienceSelectors', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const activeCnfCareAudienceSelectors = async (data) => {
  try {
    const response = await client.post('/cnf-care-audience-selector/activeCnfCareAudienceSelectorsGroup', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const inActiveCnfCareAudienceSelectors = async (data) => {
  try {
    const response = await client.post('/cnf-care-audience-selector/inActiveCnfCareAudienceSelectorsGroup', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const deleteCnfCareAudienceSelectors = async (data) => {
  try {
    const response = await client.post('/cnf-care-audience-selector/deleteCnfCareAudienceSelectorsGroup', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const downloadCnfCareAudienceSelectorGroup = async (data) => {
  try {
    const response = await client.post('/cnf-care-audience-selector/downloadCnfCareAudienceSelectorGroup', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};



