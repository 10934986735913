import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

export const getCmCareCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/getCmCareCampaigns', data);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
export const getCmCareCampaignsByStep2 = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/getCmCareCampaignsByStep2', data);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
export const getCmCareCampaignsByIdOrName = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/getCmCareCampaignsByIdOrName', data);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const getCmCareCampaignBy = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/getCmCareCampaignBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmCareCampaignById = async (id) => {
  try {
    const response = await client.post('/cm-care-campaign/getCmCareCampaignById', { id });
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const haveReferenceCmCareCampaign = async (id) => {
  try {
    const response = await client.post('/cm-care-campaign/haveReferenceCmCareCampaign', { id });
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const createCmCareCampaign = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/createCmCareCampaign', createBy(data));
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const updateCmCareCampaign = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/updateCmCareCampaign', updateBy(data));
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const activeCmCareCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/activeCmCareCampaigns', data);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export const inActiveCmCareCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/inActiveCmCareCampaigns', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const deleteCmCareCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-care-campaign/deleteCmCareCampaigns', data);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}